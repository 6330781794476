import { Container, Typography, Stack, Box, Grid, Button } from "@mui/material";
import ContactCard from "../components/card/ContactCard";
import Divider from "../components/divider/Divider";
import Marquee from "../components/marquee/Marquee";
import WhatsInTheBag from "../assets/images/WhatsInTheBag.webp";
import WeedAndFeed from "../assets/images/Bags/Management_Programs/Program_2/Weed_And_Feed_Program_2.webp";
import EarthWise from "../assets/images/Bags/Ice_Melts/Earth_Wise.webp";
import Solina from "../assets/images/Bags/Salt_Products/Solina.webp";
import ForistellImage from "../assets/images/Locations/Foristell.webp";
import BeebeImage from "../assets/images/Locations/Beebe.webp";
import { useNavigate } from "react-router-dom";
import pdfFile from "../assets/pdfs/2025-OPP-Catalog.pdf";

let grid_items = [
    {
      title: "Greenskeeper's Secret Weed & Feed",
      grid_1_order: { xs: 2, sm: 1 },
      grid_2_order: { xs: 1, sm: 2 },
      description: `Part of our Turf Management Program, apply our Weed & Feed in late Spring (April-June). It helps provide control 
    for a wide range of lawn weeds and comes in a premium quality retail bag.`,
      button_title: "All Fertilizer",
      button_link: "test",
      image: WeedAndFeed,
      url: "/Fertilizers/Greenskeepers-Secret-Turf-Management-Programs",
    },
    {
      title: "Earth-Wise",
      grid_1_order: { xs: 4, sm: 3 },
      grid_2_order: { xs: 3, sm: 4 },
      description: `Earth-Wise is an environmentally friendly for soil, grass and shrubs when applied correctly. 
    It is odorless, colorless, cost effective and an economical performer.     `,
      button_title: "All Ice Melts",
      button_link: "test",
      image: EarthWise,
      url: "/Ice-Melts/Earth-Wise-Ice-Melts",
    },
    {
      title: "Solina Solar Salt Water Softener",
      grid_1_order: { xs: 6, sm: 5 },
      grid_2_order: { xs: 5, sm: 6 },
      description: `Solina is the highest purity solar salt on the market. 
    Solina is very clean with a consistent uniformed sizing and contains very little fines which 
    helps prevent any caking problems.`,
      button_title: "All Water Condintioning Salts",
      button_link: "test",
      image: Solina,
      url: "/Water-Conditioning-Salts/Solina-Solar-Salt-Water-Softener",
    },
  ],
  locations = [
    {
      image: ForistellImage,
      name: "Foristell, Missouri",
      description: `The Foristell Facility primarily manufactures fertilizer and ice-melt products.‍`,
      information: `Oakley's Foristell Facility can be contacted by calling (636) 495-1900 and is located at 815 Hwy T Foristell, Missouri 63348.`,
    },
    {
      image: BeebeImage,
      name: "Beebe, Arkansas",
      description: `The Beebe Facility primarily manufactures feed and fertilizer products.`,
      information: `Oakley's Beebe Facility can be contacted by calling (501) 882-3355 and is located at 312 South Fir St Beebe, Arkansas 72012.`,
    },
  ];

const Dashboard = () => {
  let navigate = useNavigate();

  return (
    <>
      <Container sx={{ height: "100%", position: "relative" }} maxWidth="xl">
        <Stack sx={{ mt: 5, px: { sm: 5, md: 25, lg: 50 } }}>
          <Typography
            sx={{
              typography: { xs: "h5", sm: "h6", md: "h3" },
            }}
            align="center"
          >
            Wholesale Bagged Products
          </Typography>

          <Typography
            sx={{
              typography: { xs: "body2", sm: "body1" },
            }}
            variant="body1"
            align="center"
            mt={2}
          >
            Oakley Professional Products manufactures premium fertilizer, ice
            melt, and feed products. Its two bagging facilities are located at
            Foristell, MO and Beebe, AR.
            <br />
            <br />
            Bag sizes for fertilizers and ice melts range from 10 to 50 pounds.
            Feed products are in 50 pound bags. Most products can be sold in 1
            ton tote bags.
          </Typography>
        </Stack>

        <Box sx={{ height: "300px", display: { xs: "none", md: "flex" } }}>
          <Marquee />
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            style={{
              backgroundColor: "#094081", // Blue color
              fontSize: "1.2rem", // Bigger text
              textAlign: "center", // Ensures the text is centered
              padding: "10px 10px",
            }}
            href={pdfFile}
            target="_blank" // Opens the PDF in a new tab
            rel="noopener noreferrer" // Security improvement for external links
          >
            2025 Oakley Professional Products Catalog
          </Button>
        </Box>

        <Divider />

        <Container>
          <Grid container>
            <Grid item xs={12} lg={7} order={{ xs: 2, lg: 1 }}>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Stack spacing={1}>
                  <Typography
                    sx={{
                      typography: { xs: "h5", sm: "h6", md: "h3" },
                    }}
                    align="center"
                  >
                    Understanding What's In the Bag
                  </Typography>

                  <Typography
                    sx={{
                      typography: { xs: "body2", sm: "body1" },
                    }}
                    align="center"
                  >
                    The best way for you to know what kind of fertilizer you
                    need is by understanding what is in the bag and what it will
                    do. First, it is important to answer this question: What is
                    an analysis? The 3 numbers on the front of the fertilizer
                    bag are what make up what is generally known as “an
                    analysis”.
                  </Typography>

                  <Box display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      sx={{ width: 125 }}
                      onClick={() =>
                        navigate("/Understanding-Whats-In-The-Bag")
                      }
                    >
                      Read More
                    </Button>
                  </Box>
                </Stack>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              lg={5}
              order={{ xs: 1, lg: 2 }}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                component="img"
                alt="What's In The Bag"
                sx={{ width: { xs: "100%", sm: "50%", md: "50%", lg: "100%" } }}
                src={WhatsInTheBag}
              />
            </Grid>
          </Grid>
        </Container>

        <Divider />

        <Container maxWidth="md" sx={{ mb: 5 }}>
          <Grid container spacing={3}>
            {grid_items.map((grid, index) => (
              <Grid container item xs={12} key={index}>
                <Grid item xs={12} sm={8} order={grid.grid_1_order} key={index}>
                  <Box display="flex" alignItems="center" height="100%">
                    <Stack spacing={1}>
                      <Typography
                        sx={{
                          typography: { xs: "h5", sm: "h6", md: "h3" },
                        }}
                      >
                        {grid.title}
                      </Typography>

                      <Typography
                        sx={{
                          typography: { xs: "body2", sm: "body1" },
                        }}
                      >
                        {grid.description}
                      </Typography>

                      <Button
                        variant="contained"
                        sx={{ width: 300 }}
                        onClick={() => navigate(grid.url)}
                      >
                        {grid.button_title}
                      </Button>
                    </Stack>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  justifyContent="center"
                  display="flex"
                  order={grid.grid_2_order}
                >
                  <Box
                    component="img"
                    alt="Bag"
                    src={grid.image}
                    sx={{ width: { xs: "50%", sm: "100%", md: "50%" } }}
                  />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>
      <Box sx={{ backgroundColor: "#094081", color: "white", padding: 5 }}>
        <Container>
          <Typography align="center" variant="h4">
            Locations
          </Typography>

          <Grid container spacing={2}>
            {locations.map((location, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Stack
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Box
                    component="img"
                    alt={location.name}
                    src={location.image}
                    sx={{ width: "100%", height: "85%", p: 2 }}
                  />

                  <Typography align="center" variant="h6">
                    {location.name}
                  </Typography>
                  <Typography align="center" variant="body2">
                    {location.description}
                  </Typography>
                  <Typography align="center" variant="body2" mt={2}>
                    {location.information}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Container sx={{ my: 3 }}>
        <ContactCard />
      </Container>
    </>
  );
};

export default Dashboard;
